export default {
  title: 'Settings',

  importantData: {
    balance: 'inventory',
    employeeCount: 'Number of employees'
  },

  buttons: {
    save: 'save changes',
    reset: 'undo changes',
    removeLogo: 'remove logo',
    uploadLogo: 'Load Logo'
  },

  companyInfo: {
    title: 'Company Info',

    labels: {
      generalInfo: 'General info',
      telInfo: 'Tell info',
      financialInfo: 'Financial info',
      uploadLogo: 'Upload logo',
      preview: 'Preview:',
      name: 'Company name',
      managerName: 'Manager full name',
      country: 'Country',
      province: 'State',
      city: 'City',
      address: 'Address',
      postCode: 'Post code',
      phoneNumber: 'Phone number',
      whatsAppSupport: 'WhatsApp support',
      managerPhoneNumber: 'Manager phone number'
    },

    description: {
      logoRule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB ',
      generalInfo: 'This box displays the general information of the company.',
      telInfo: 'In this box the company contact information is displayed.',
      financialInfo: 'This box displays the companys financial information.'
    },

    validators: {
      imageSize: 'The selected file size is too large',
      fileFormat: 'The selected file format is not allowed',
      name: 'Name is not valid'
    },

    notifications: {
      logo: {
        upload: {
          success: 'Logo uploaded successfully'
        },
        delete: {
          success: 'Logo removed successfully'
        }
      },

      edit: {
        success: 'Store information updated successfully'
      }
    }
  },

  accessGroup: {
    title: 'User Groups',

    notifications: {
      insert: {
        success: 'User group added successfully',
        error: 'Add user group failed'
      },

      edit: {
        success: 'User group successfully edited',
        error: 'User group editing encountered an error'
      },

      delete: {
        success: 'User group successfully deleted',
        error: 'The user group could not be found.',
        cantDelete: 'The user group could not be deleted because it has a subgroup.'
      },

      deleteUser: {
        success: 'User successfully deleted from group',
        error: 'Delete user from group has error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete User Group',
        body: 'Are you sure you want to delete {name}?'
      },

      deleteUser: {
        title: 'Delete User From User Group',
        body: 'Are you sure you want to delete {name} from this group?'
      }
    },

    permissions: {
      event_menu: 'Messages',
      event: {
        show: 'View Messages',
        create: 'Add Message',
        update: 'Edit Message',
        delete: 'Delete Message',
      },
      user_management_menu: 'People',
      user_menu: 'People List',
      user: {
        show: 'View People',
        show_transactions: 'View People Transactions',
        create: 'Add Person',
        update: 'Fully Edit Person',
        delete: 'Temporarily Delete Person',
        restore: 'Restore Person',
        forceDelete: 'Permanently Delete Person',
        set_role: 'Assign Role to Person',
        set_group: 'Assign Customer Group to Person',
        set_max_debt: 'Assign Credit to Person',
      },
      user_group_menu: 'Customer Groups',
      user_group: {
        show: 'View Customer Groups',
        create: 'Add Customer Group',
        update: 'Fully Edit Customer Group',
        delete: 'Temporarily Delete Customer Group',
        restore: 'Restore Customer Group',
        forceDelete: 'Permanently Delete Customer Group',
        assign_to_user: 'Assign Customer Group to Customer',
      },
      role_menu: 'Access Levels',
      role: {
        show: 'View Access Levels',
        create: 'Add Access Level',
        update: 'Fully Edit Access Level',
        delete: 'Delete Access Level',
      },
      product_and_services_menu: 'Products and Services',
      products_menu: 'Product List',
      services_menu: 'Services List',
      service: {
        show: 'View Services',
        create: 'Add New Service',
        update: 'Edit Service',
        delete: 'Delete Service',
        restore: 'Restore Service',
        forceDelete: 'Permanently Delete Service',
      },
      categories_menu: 'Product Categories',
      attributes_menu: 'Attributes',
      attribute: {
        show: 'View Attributes',
        create: 'Add Attribute',
        update: 'Edit Attributes',
        updatePriorities: 'Edit Attribute Priorities',
        delete: 'Delete Attribute',
      },
      attribute_values_menu: 'Attribute Values',
      attribute_value: {
        show: 'View Attribute Values',
        create: 'Add Attribute Value',
        update: 'Edit Attribute Value',
        delete: 'Delete Attribute Value',
      },
      warehousing_menu: 'Inventory',
      storeroom_menu: 'Storeroom',
      storeroom: {
        show: 'View Storeroom',
        create: 'Add Storeroom',
        update: 'Fully Edit Storeroom',
        delete: 'Temporarily Delete Storeroom',
        forceDelete: 'Permanently Delete Storeroom',
        restore: 'Restore Storeroom',
        turnover: 'View Storeroom Inventory Turnover',
        inventory: 'View Storeroom Quantity Review',
        inventoryValuation: 'View Storeroom Value Review',
      },
      storeroom_receipts_menu: 'Storeroom Receipts',
      storeroom_receipt: {
        show: 'View Storeroom Receipt',
        create: 'Add Storeroom Receipt',
        update: 'Fully Edit Storeroom Receipt',
        refresh_status: 'Change Storeroom Receipt Status',
        accept: 'Approve Storeroom Receipt',
        partial_accept: 'Partial Approval of Storeroom Receipt',
        delete: 'Temporarily Delete Storeroom Receipt',
      },
      storeroom_deficits_menu: 'Storeroom Deficits and Surpluses',
      storeroom_deficit: {
        show: 'View Storeroom Deficits and Surpluses',
        create: 'Add Storeroom Deficit and Surplus',
        update: 'Edit Storeroom Deficits and Surpluses',
        fullUpdate: 'Fully Edit Storeroom Deficits and Surpluses',
        delete: 'Temporarily Delete Storeroom Deficits and Surpluses',
      },
      storeroom_conversions_menu: 'Inventory Conversions',
      storeroom_conversion: {
        show: 'View Inventory Conversions',
        create: 'Add Inventory Conversion',
        update: 'Edit Inventory Conversion',
        fullUpdate: 'Fully Edit Inventory Conversion',
        delete: 'Temporarily Delete Inventory Conversion',
      },
      treasury_menu: 'Treasury',
      bank_menu: 'Banks',
      bank: {
        show: 'View Banks',
        show_transactions: 'View Bank Transactions',
        create: 'Add Bank',
        update: 'Edit Bank',
        delete: 'Delete Bank',
        restore: 'Restore Bank',
        forceDelete: 'Permanently Delete Bank',
      },
      payment_gateway_menu: 'Payment Gateways',
      payment_gateway: {
        show: 'View Payment Gateways',
        show_transactions: 'View Payment Gateway Transactions',
        create: 'Add Payment Gateway',
        update: 'Edit Payment Gateway',
        delete: 'Delete Payment Gateway',
        restore: 'Restore Payment Gateway',
        forceDelete: 'Permanently Delete Payment Gateway',
      },
      cash_menu: 'Cash Registers',
      cash: {
        show: 'View Cash Registers',
        show_transactions: 'View Cash Transactions',
        create: 'Add Cash Register',
        update: 'Edit Cash Register',
        delete: 'Delete Cash Register',
        restore: 'Restore Cash Register',
        forceDelete: 'Permanently Delete Cash Register',
      },
      payment_menu: 'Payment',
      payment: {
        show: 'View Payment',
        create: 'Add Payment',
        delete: 'Delete Payment',
        restore: 'Restore Payment',
        forceDelete: 'Permanently Delete Payment',
        dynamicDate: 'Register Payment for the Past',
      },
      receive_menu: 'Receive',
      receive: {
        show: 'View Receive',
        create: 'Add Receive',
        return: 'Return Receive',
        delete: 'Delete Receive',
        restore: 'Restore Receive',
        forceDelete: 'Permanently Delete Receive',
        dynamicDate: 'Register Receive for the Past',
      },
      cost_menu: 'Cost',
      cost: {
        show: 'View Cost',
        create: 'Add Cost',
        delete: 'Delete Cost',
        restore: 'Restore Cost',
        forceDelete: 'Permanently Delete Cost',
        dynamicDate: 'Register Cost for the Past',
      },
      internal_fund_menu: 'Internal Fund Transfer',
      internal_fund: {
        show: 'View Internal Fund Transfer',
        create: 'Add Internal Fund Transfer',
        delete: 'Delete Internal Fund Transfer',
        restore: 'Restore Internal Fund Transfer',
        forceDelete: 'Permanently Delete Internal Fund Transfer',
        dynamicDate: 'Register Internal Fund Transfer for the Past',
      },
      accountancy_menu: 'Accountancy',
      opening: {
        access: 'Full Access to Start of Period',
      },
      document: {
        access: 'Full Access to Documents',
      },
      accounts_tree: 'Accounts Tree',
      account: {
        access: 'Full Access to Accounts Tree',
        force_update: 'Mandatory Edit of Accounts Tree',
      },
      tax_menu: 'Tax',
      tax: {
        show: 'View Tax',
        create: 'Add Tax',
        delete: 'Delete Tax',
        restore: 'Restore Tax',
        forceDelete: 'Permanently Delete Tax',
        dynamicDate: 'Register Tax for the Past',
      },
      sales_menu: 'Sales',
      sale_invoices_menu: 'Sales Invoices',
      sale_invoice: {
        show: 'View Sales Invoices',
        create: 'Add Sales Invoice',
        update: 'Edit Sales Invoice',
        fullUpdate: 'Fully Edit Sales Invoice',
        complete: 'Complete Sales Invoice',
        refreshStatus: 'Revert Sales Invoice Status',
        delete: 'Temporarily Delete Sales Invoice',
        forceDelete: 'Permanently Delete Sales Invoice',
        restore: 'Restore Sales Invoice',
      },
      return_sale_invoices_menu: 'Sales Return Invoices',
      return_sale_invoice: {
        show: 'View Sales Return Invoices',
        create: 'Add Sales Return Invoice',
        update: 'Edit Sales Return Invoice',
        fullUpdate: 'Fully Edit Sales Return Invoice',
        refreshStatus: 'Revert Sales Return Invoice Status',
        delete: 'Temporarily Delete Sales Return Invoice',
        forceDelete: 'Permanently Delete Sales Return Invoice',
        restore: 'Restore Sales Return Invoice',
      },
      suggest_sale_invoice: {
        show: 'View Sales Suggestions',
      },
      purchases_menu: 'Purchases',
      purchase_invoices_menu: 'Purchase Invoices',
      purchase_invoice: {
        show: 'View Purchase Invoices',
        create: 'Add Purchase Invoice',
        update: 'Edit Purchase Invoice',
        fullUpdate: 'Fully Edit Purchase Invoice',
        refreshStatus: 'Revert Purchase Invoice Status',
        delete: 'Temporarily Delete Purchase Invoice',
        forceDelete: 'Permanently Delete Purchase Invoice',
        restore: 'Restore Purchase Invoice',
      },
      purchase_invoice_lines_menu: 'Purchase Invoice Lines',
      purchase_invoice_line: {
        create: 'Add Purchase Invoice Item',
        update: 'Edit Purchase Invoice Item',
        fullUpdate: 'Fully Edit Purchase Invoice Item',
        delete: 'Delete Purchase Invoice Item',
      },
      return_purchase_invoices: 'Purchase Return Invoices',
      return_purchase_invoice: {
        show: 'View Purchase Return Invoices',
        create: 'Add Purchase Return Invoice',
        update: 'Edit Purchase Return Invoice',
        fullUpdate: 'Fully Edit Purchase Return Invoice',
        refreshStatus: 'Revert Purchase Return Invoice Status',
        delete: 'Temporarily Delete Purchase Return Invoice',
        forceDelete: 'Permanently Delete Purchase Return Invoice',
        restore: 'Restore Purchase Return Invoice',
      },
      suggest_purchase_invoice: {
        show: 'View Purchase Suggestions',
      },
      connector_menu: 'Connector',
      shops_menu: 'Shops',
      shop: {
        show: 'View Shops',
        create: 'Add New Shop',
        update: 'Edit Shop',
        update_setting: 'Edit Shop Configuration',
        update_storeroom: 'Edit Shop Storerooms',
        delete: 'Temporarily Delete Shop',
        restore: 'Restore Shop',
        forceDelete: 'Permanently Delete Shop',
      },
      woo_controllers_menu: 'Controllers',
      woo_controller_attribute: {
        access: 'Full Access to Attribute Controller',
      },
      woo_controller_product: {
        access: 'Full Access to Product Controller',
      },
      woo_controller_product_price: {
        access: 'Full Access to Product Price Controller',
      },
      woo_controller_product_inventory: {
        access: 'Full Access to Product Inventory Controller',
      },
      woo_controller_user: {
        access: 'Full Access to Customer Controller',
      },
      woo_controller_invoice: {
        access: 'Full Access to Order Controller',
      },
      woo_controller_payment_gateways: {
        access: 'Full Access to Payment Gateway Controller',
      },
      organizational_ticket_menu: 'Organizational Tickets',
      organizational_ticket: {
        show: 'View Organizational Tickets',
        create: 'Create Organizational Ticket',
        delete: 'Delete Organizational Ticket',
      },
      financial_year_menu: 'Financial Years',
      financial_year: {
        show: 'View Financial Year',
        create: 'Create Financial Year',
        switch: 'Change Financial Year',
      },
      setting_menu: 'Settings',
      setting: {
        show: 'View Settings',
        update: 'Edit Settings',
        reset_application: 'Reset Application',
      },
      reports: 'Reports',
      report_tops: {
        show: 'View Top Reports',
      },
      report_chart: {
        show: 'View Charts',
      },
      gross_profit_report: {
        show: 'View Gross Profit Report',
      },
      attributes_profit_report: {
        show: 'View Attribute Gross Profit Report',
      },
      activity_logs: {
        show: 'View Logs',
      },
      log: {
        show: 'Full Access to Logs',
      },
      backup_menu: 'Backup',
      backup: {
        show: 'View Recent Backup Files',
        create: 'Create Backup File',
        restore: 'Restore Backup File',
      },
      is_addons: 'Add-ons',
      addons: {
        woo_controller: 'Controller',
        warehousing: 'Warehousing',
        max_debt: 'Credit Limit',
        points: 'Points'
      },
    },

    insert: {
      title: 'Add a new user group',

      labels: {
        name: 'User Group Title',
        allowedActiveSession: 'Active sessions allowed',
        access: 'access',
        insert: 'Add',
        inMenu: 'Menu',

        accessList: {
          manager: 'General Manager Access',

          users: {
            totalAccess: 'Full access of persons',
            show: 'View People',
            insert: 'Add person',
            update: 'full edit',
            delete: 'Temporarily delete person',
            setRole: 'Assign a role to a person'
          },

          roles: 'Full access to roles',
          treasury: {
            title: 'Full access to the Treasury',

            bank: 'full access to banks',
            cash: 'Full access to funds'
          },

          reports: {
            title: 'Full access to reports',
            logs: 'access logs'
          },

          delete: {
            title: 'Permanent removal',

            user: 'People',
            banks: 'Banks',
            cash: 'Cashiers'
          }
        }
      },

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    edit: {
      title: 'Edit User Group',

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    insertUserToAccessGroup: {

      notifications: {
        success: 'access group successfully set to user',
        error: 'set access group to user failed',
        noUserSelected: 'no user selected'
      }
    },

    table: {

      header: {
        row: 'Row',
        name: 'Access Title'
      }
    },

    actions: {
      insert: 'Add',
      save: 'Register'
    }
  },

  users: {
    title: 'People Settings',

    labels: {
      gender: 'gender mandatory'
    }
  },

  security: {
    title: 'Security',

    labels: {
      disActive: 'Compulsory exit period if not active',
      minute: 'minute'
    }
  },

  customize: {
    title: 'System Personalization',

    labels: {
      theme: 'Select the desired theme'
    }
  },

  financial: {
    title: 'Financial',

    rule: 'Rules',
    tax: 'Vat',
    paymentInfo: 'Payment info',
    NegativeValue: 'Bank and fund can be negative',

    labels: {
      activeMaxDebt: 'Enable max debt',
      purchaseTax: 'Purchase vat percent',
      saleTax: 'Sale vat percent',
      vat: 'Vat tax percent',
      taxRegistrationNumber: 'Tax Registration Number',
      bankName: 'Bank name',
      accountName: 'Account name',
      accountNumber: 'Account number',
      iban: 'IBAN',
      swiftCode: 'Swift code',
      cardNumber: 'Card number'
    },

    notifications: {
      negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings has error',
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Financial information has been updated successfully',
        error: 'Update financial information has error'
      }
    }
  },

  product: {
    title: 'Product',

    labels: {
      addAttributeManually: 'Enable add attribute to product manually'
    },

    notifications: {
      edit: {
        success: 'Product information has been updated successfully',
        error: 'Update product information has error'
      }
    }
  },

  tax: {
    title: 'Vat',

    labels: {
      enableTax: 'Enable vat',
      purchaseTax: 'Purchase vat percent',
      saleTax: 'Sale vat percent',
      taxRegistrationNumber: 'Tax Registration Number',
      enableShippingTax: 'Shipping price includes vat'
    },

    notifications: {
      edit: {
        success: 'Vat information has been updated successfully',
        error: 'Update vat information has error'
      }
    }
  },

  shipping: {
    title: 'Shipping',

    labels: {
      cost: 'Shipping cost'
    },

    notifications: {
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Shipping information has been updated successfully',
        error: 'Update shipping information has error'
      }
    }
  },

  print: {
    title: 'Print',

    labels: {
      logos: 'Images',
      invoiceLogo: 'Invoice logo',
      postalLabelLogo: 'Postal label logo',
      receiptLogo: 'Receipt logo',
      treasuryLogo: 'Treasury logo',
      companyNames: 'Company names',
      invoiceCompanyName: 'Invoice company name',
      postalLabelCompanyName: 'Postal label company name',
      postalLabelPrintCompanyName: 'Postal label print company name',
      warehouseReceiptCompanyName: 'Warehouse receipt company name',
      treasuryCompanyName: 'Treasury company name',
      lightLogo: 'Light logo',
      stamp: 'Stamp',
      QR: 'QR',
      theme: 'Postal label template',
      companyInfoWithZeroVat: 'If vat is zero, company info show in print invoice'
    },

    themeOptions: {
      theme1: 'A4 Portrait',
      theme2: 'A5 Portrait'
    },

    notifications: {
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Print information has been updated successfully',
        error: 'Update Print information has error'
      }
    }
  },

  financialYear: {
    title: 'Financial year',

    statusTypes: {
      all: 'All',
      active: 'Active',
      disActive: 'inactive'
    },

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Name',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        show: 'Show'
      }
    },

    notifications: {
      isActived: 'The desired financial year has already been activated',

      insert: {
        success: 'The new financial year was successfully registered',
        error: 'Register financial year has error'
      },

      edit: {
        success: 'Financial year was successfully edited',
        error: 'Edit financial year has error'
      },

      switch: {
        success: 'Financial year successfully switched',
        error: 'Switch financial year has error'
      },

      parseError: {
        period: 'The selected fiscal year time range is not valid',
        paymentGateways: 'The balance of a number of payment gateways has not been announced!',
        storeroom: 'A number of warehouse pull in have not been confirmed!',
        periodNotEnded: 'The current financial year has not yet ended!'
      }
    },

    confirmations: {
      insert: {
        title: 'Insert financial year confirmation',
        body: 'Are you sure about creating a new financial year?'
      },

      show: {
        title: 'View financial year confirmation',
        body: 'Are you sure you want view {name} fiscal year?'
      }
    },

    edit: {
      title: 'Financial year configuration',

      periodTypes: {
        3: '3 months',
        6: '6 months',
        12: '1 year'
      },

      labels: {
        name: 'Name',
        startDate: 'Start Date',
        endDate: 'End Date',
        period: 'Financial year time range'
      }
    }
  },

  message: {
    title: 'SMS panel',

    labels: {
      provider: 'Provider',
      farazsms: 'Fraz SMS',
      smsglobal: 'SMS Global',
      farazsmsUsername: 'Fraz SMS Username',
      farazsmsPassword: 'Fraz SMS Password',
      farazsmsSender: 'Fraz SMS Sender',
      smsglobalUsername: 'SMS Global Username',
      smsglobalPassword: 'SMS Global Password',
      smsglobalSender: 'SMS Global Sender',
      messangerKey: 'Authentication code',
      templates: 'Templates',
      login: 'Login SMS template',
      signup: 'Register SMS template',
      confirmInfo: 'Verify SMS template'
    },

    notifications: {
      edit: {
        success: 'The SMS panel information of the company has been successfully updated'
      }
    },

    invalidTexts: {
      onlyEnglish: 'Only English characters are allowed!'
    }
  },

  General: {
    title: 'General',
    logo: {
      rule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB '
    },
    club: {
      code: 'company code',
      name: 'company name',
      managerName: 'managers first and last name',
      address: 'Address',
      phoneNumber: 'Mobile Number',
      managerPhoneNumber: 'CEO Mobile Number'
    }
  },

  Treasury: {
    title: 'Treasury',
    rule: 'Rules',
    NegativeValue: 'Banks and funds can be negative',

    notifications: {
      negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings were saved with an error',

      edit: {
        success: 'Treasury information has been updated successfully',
        error: 'Update treasury information has error'
      }
    }
  },

  Locale: {
    title: 'Languages',
    language: 'Language',
    basicSetting: 'Basic settings',

    labels: {
      basicSetting: 'Base setting',
      defaultLanguage: 'Club default language',
      defaultCurrency: 'Club default currency',
      region: 'Club default region',
      defaultCountryCode: 'Club default country code'
    },

    currency: {
      IRT: 'IRT',
      IRR: 'IRR',
      USD: 'USD',
      AED: 'AED',
      other: 'other'
    },

    notifications: {
      edit: {
        success: 'Locale setting was successfully edited',
        error: 'Edit locale setting has error'
      }
    }
  },

  warehouse: {
    title: 'Warehousing',

    labels: {
      checkExitReceiptByRow: 'Pull out confirmation by row'
    },

    notifications: {
      edit: {
        success: 'Edit inventory setting successfully done.',
        error: 'Edit inventory setting has error.'
      },
      parseError: {
        defaultStoreroomForPersonalSale: 'Selected default warehouse is not valid!'
      }
    }
  },

  purchase: {
    title: 'Purchase',

    labels: {
      defaultStoreroom: 'Default warehouse for purchase',
      hasPrePurchaseInvoiceAge: 'Pre-purchase invoice deleted after special time',
      prePurchaseInvoiceAge: 'Pre-purchase invoice deleted after ... days.',
      hasPreReturnPurchaseInvoiceAge: 'Pre-purchase return invoice deleted after special time',
      preReturnSaleInvoiceAge: 'Pre-purchase return invoice deleted after ... days.'
    },

    notifications: {
      edit: {
        success: 'Edit purchase setting successfully done',
        error: 'Edit purchase setting has error'
      },

      parseError: {
        prePurchaseInvoiceAge: 'Number of days for delete pre-purchase invoice is incorrect',
        preReturnSaleInvoiceAge: 'Number of days for delete pre-purchase return invoice is incorrect'
      }
    }
  },

  sale: {
    title: 'Sale',

    labels: {
      score: 'point',
      defaultStoreroom: 'Default warehouse for personal sale',
      hasPreSaleInvoiceAge: 'Pre-sale invoice deleted after special time',
      preSaleInvoiceAge: 'Pre-sale invoice deleted after ... days.',
      hasPreReturnSaleInvoiceAge: 'Pre-sale return invoice deleted after special time',
      preReturnSaleInvoiceAge: 'Pre-sale return invoice deleted after ... days.',
      profitConversationRate: 'Profit conversation rate',
      enableRetailShipping: 'Retail sale has shipping',
      enableSalePoint: 'Enable sale point',
      defaultBank: 'Default bank',
      defaultCash: 'Default cash',
      defaultPaymentGateway: 'Default paymentGateway'
    },

    notifications: {
      edit: {
        success: 'Edit sale setting successfully done',
        error: 'Edit sale setting has error'
      },

      parseError: {
        preSaleInvoiceAge: 'Number of days for delete pre-sale invoice is incorrect',
        preReturnSaleInvoiceAge: 'Number of days for delete pre-sale return invoice is incorrect'
      }
    }
  },

  reset: {
    title: 'Reset',

    counts: {
      costs: '"{count}" costs exist',
      documents: '"{count}" documents exist',
      financial_years: '"{count}" financial years exist',
      internal_funds: '"{count}" internal funds exist',
      invoices: '"{count}" invoices exist',
      opening_inventories: '"{count}" opening inventories exist',
      payments: '"{count}" payments exist',
      receives: '"{count}" receives exist',
      storeroom_receipts: '"{count}" warehouse pull in exist'
    },

    labels: {
      companyName: 'Name',
      confirm: 'Confirm, reset app',
      codeValidate: 'Code validity ',
      second: 'Second',
      resendCode: 'Resend Code',
      storedData: 'You are about to reset app containing:',
      resetConfirm: 'Are you absolutely sure?',
      enterConfirm: 'Enter the following to confirm:'
    },

    notifications: {
      otpExpired: 'Code expired',
      otpWrong: 'Code is wrong',

      sendOtp: {
        success: 'The verification code of reset app was sms to your phone number.',
        error: 'Send verification code of reset app has error',
        unSaveChanges: 'There are some unsaved changes in app'
      },
      confirmReset: {
        success: 'Reset app successFully done',
        error: 'Reset app has error'
      }
    },

    confirmations: {
      reset: {
        title: 'Reset app',
        body: 'Are you sure about reset app?'
      }
    }
  },

  report: {
    title: 'Reports',

    labels: {
      email: 'Email',
      daily: 'Active emails for send daily reports',
      hooks: {
        purchase_invoice: {
          create: 'Create purchase invoice',
          update: 'Update purchase invoice',
          delete: 'Delete purchase invoice'
        },
        return_purchase_invoice: {
          create: 'Create purchase refund',
          update: 'Update purchase refund',
          delete: 'Delete purchase refund'
        },
        sale_invoice: {
          create: 'Create sale invoice',
          update: 'Update sale invoice',
          delete: 'Delete sale invoice'
        },
        return_sale_invoice: {
          create: 'Create sale refund',
          update: 'Update sale refund',
          delete: 'Delete sale refund'
        }
      }
    },

    notifications: {
      edit: {
        success: 'Edit report setting successfully done',
        error: 'Edit report setting has error'
      },

      parseError: {
        email: 'Email is not valid',
        categories: 'No category selected',
        emailExist: 'Email is exist in the list',
      }
    },

    confirmations: {
      delete: {
        title: 'Delete email confirmation',
        body: 'Are you sure about delete {name}?'
      }
    },

    insert: {
      title: 'افزودن ایمیل'
    },

    edit: {
      title: 'ویرایش ایمیل'
    }
  }
}
