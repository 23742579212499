export default {
  title: 'Products & Services',

  list: {
    title: 'Products',

    table: {
      header: {
        row: 'Row',
        name: 'Title',
        shopName: 'Ecommerce Name',
        code: 'Code',
        barcode: 'Barcode',
        price: 'Price',
        image: 'Image',
        category: 'Category',
        creator: 'Creator',
        type: 'Type',
        status: 'Status',
        shops: 'Ecommerce',
        lastModifiedDate: 'Update'
      },

      filters: {
        status: {
          all: 'All',
          notConnect: 'no connection',
          draft: 'draft',
          waiting: 'pending',
          published: 'published',
          personal: 'private'
        },

        type: {
          all: 'All',
          simpleProduct: 'simple',
          variableProduct: 'Variable'
        }
      }
    }
  },

  insert: {
    title: 'Add Product',

    labels: {
      importData: 'Upload product opening balance'
    },

    types: {
      label: 'Select add type',
      useral: 'Add to useral',
      woocommerce: 'Add to {shopName} and username'
    },

    table: {
      header: {
        inventory: 'Inventory',
        barcode: 'Barcode',
        price: 'Price',
        salePrice: 'Sale price',
        delete: 'Delete'
      }
    },

    description: {
      title: 'Description',

      useralDescription: 'The information of this product is registered only in the useral system.',
      woocommerceDescription: 'Product information is recorded directly in your store under review status.',
      notCategorySelected: 'No categories selected',
      noAttributesExistForCategory: 'There are no attributes for this category',
      noAttributeChooseForVariables: 'No attribute selected as variable',
      inventory: 'On this page you can enter the entire product inventory. If the number of inventories is zero, the product will be considered non-existent. ',
      attribute: 'You can select the Attributes you want and add it to the product Attributes list. If the product is a variable type, you can select <b> one </b> of the Attributes as the product variable attribute '
    },

    notifications: {
      type: {
        notSelected: {
          error: 'Add type not selected'
        }
      },
      notConnectToServer: {
        error: 'You are not able to add the product due to not connecting to the site'
      },
      imageUpload: {
        success: 'Photo uploaded successfully'
      },
      imageRemove: {
        success: 'Logo removed successfully'
      },
      insertProduct: {
        success: 'Product information successfully registered',
        error: 'Registration of product information encountered an error'
      },
      woocommerceInsertProduct: {
        success: 'Product information was successfully registered in the store',
        error: 'Registration of product information encountered an error'
      }
    },

    accordionList: {
      brand: {
        title: 'Select the desired brand',

        insert: {
          title: 'Add a new brand',
          brand: 'brand title'
        },

        edit: {
          title: 'Edit Brand',
          category: 'Brand Title'
        },

        delete: {
          title: 'Delete Brand',
          message: 'By deleting this brand, all related information will also be deleted.'
        }
      },

      category: {
        title: 'Select the desired category',

        insert: {
          title: 'Add new category',
          category: 'Category Title'
        },

        edit: {
          title: 'Edit Category',
          category: 'Category Title'
        },

        delete: {
          title: 'Delete Category',
          message: 'Deleting this category will delete all related information.'
        }
      },


      attributes: 'Properties',
      insertAttribute: {
        title: 'Add new feature',
        attribute: 'attribute title',
        values: 'values'
      },
      editAttribute: {
        title: 'Edit Feature',
        attribute: 'attribute title',
        values: 'values'
      },

      buttons: {
        accept: 'Confirm',
        cancel: 'Cancel'
      },

      alert: {
        title: 'Message',
        brandCreated: 'Brand created successfully',
        brandUpdated: 'Brand successfully edited',
        brandDeleted: 'Brand successfully deleted',
        categoryCreated: 'Category created successfully',
        categoryUpdated: 'Category edited successfully',
        categoryDeleted: 'Category deleted successfully',
        errorTitle: 'error',
        error: 'The process went wrong'
      }
    }
  },

  edit: {
    title: 'Edit Product',
    dynamicTitle: 'Edit {name}',

    table: {
      header: {
        inventory: 'Inventory',
        barcode: 'Barcode',
        price: 'Price',
        salePrice: 'Sale price',
        delete: 'Delete'
      }
    },

    description: {
      title: 'Description',

      variableDescription: 'You can edit information about each variable in your store through this page.',
      useralDescription: 'The information of this product is registered only in the useral system.',
      woocommerceDescription: 'Product information is recorded directly in your store under review status.',
      notCategorySelected: 'No categories selected',
      noAttributesExist: 'There are no attributes for this category',
      noAttributeChooseForVariables: 'No attribute selected as variable',
      inventory: 'On this page you can enter the entire product inventory. If the number of inventories is zero, the product will be considered non-existent. ',
      attribute: 'You can select the Attributes you want and add it to the product Attributes list. If the product is a variable type, you can select <b> one </b> of the Attributes as the product variable attribute '
    },

    notifications: {
      type: {
        notSelected: {
          error: 'Add type not selected'
        }
      },
      notConnectToServer: {
        error: 'You are not able to add the product due to not connecting to the site'
      },
      imageUpload: {
        success: 'Photo uploaded successfully'
      },
      imageRemove: {
        success: 'Logo removed successfully'
      },
      editProduct: {
        success: 'Product information successfully edited',
        error: 'Edit product information encountered an error'
      },
      woocommerceEditProduct: {
        success: 'Product information was successfully registered in the store',
        error: 'Registration of product information encountered an error'
      },
      deleteVariant: {
        success: 'Variable information successfully deleted',
        error: 'Deleting variable information was associated with an error'
      },
      deleteProduct: {
        success: 'Product information successfully deleted',
        error: 'Deleting product information was accompanied by an error'
      }
    },

    confirmations: {
      removeProduct: {
        title: 'Confirmation',
        body: 'Are you sure you want to delete this item?'
      },
      removeWoocommerceProduct: {
        title: 'Confirmation',
        body: 'Are you sure you want to delete this item? If confirmed, product information will also be removed from your store! '
      },
      changeProductType: {
        title: 'Warning',
        body: 'If you change the product type of the variable to simple, the information of all variables will be deleted'
      },
      changeVariantAttribute: {
        title: 'Warning',
        body: 'َAfter variant attribute changed, the variants data will be delete. Are you sure?'
      },
      deleteVariable: {
        title: 'Confirmation',
        body: 'Are you sure you want to delete this variable? If confirmed, product information will also be removed from your store! '
      }
    }
  },

  product: {
    title: 'Product',

    labels: {
      withoutPrice: 'No Price',
      rangePrice: 'From {min} to {max}',
      openingBalance: 'Opening inventory'
    },

    importantData: {
      price: 'Price',
      salePrice: 'Sale price',
      score: 'Point',
      category: 'Category',
      type: 'type'
    },

    actions: {
      barcode: 'Product barcode',
      saleTurnover: 'Product turnover',
      warehouseTurnover: 'Product warehouse turnover',
      point: 'Product points',
      attributes: 'Product attributes',
      variants: 'Product variables',
      logs: 'Activity logs'
    },


    attributes: {
      title: 'Product Attributes',
      dynamicTitle: 'Attributes {name}',

      table: {
        header: {
          rowNumber: 'Row',
          key: 'Attribute title',
          value: 'Attribute value'
        }
      }
    },

    barcode: {
      title: 'Product Barcode',
      dynamicTitle: '{name} barcode',

      labels: {
        barcodeType: 'Barcode type',
        barcodeValue: 'Barcode value',
        barcode: 'Barcode',
        QRCode: 'QRCode',
        siteLink: 'Product link',
        barcodeLink: 'Product barcode',
        hasImage: 'Show image',
        cardSize: 'Card size',
        cardPieces: '{count} pieces'
      }
    },

    turnover: {
      sale: {
        title: 'Product sale turnover',
        dynamicTitle: '{name} sale turnover',
      },

      storeroom: {
        title: 'Product warehouse turnover',
        dynamicTitle: '{name} warehouse turnover',
      },

      table: {
        header: {
          row: 'Row',
          documentId: 'Delivery note id',
          invoiceId: 'Invoice id',
          date: 'Date',
          destination: 'Account side',
          description: 'Description',
          unitPrice: 'Unit price',
          quantity: 'Count',
          inventory: 'Inventory'
        }
      },

      labels: {
        dynamicStoreReceipt: 'Delivery Note No. {id}'
      },

      typeMapper: {
        openingBalance: 'Opening balance',
        receive: 'Receipt of warehouse',
        exit: 'exit warehouse',
        transfer: 'transfer'
      },

      statusMapper: {
        sent: 'Posted',
        onWay: 'On the way',
        received: 'Reached warehouse',
        waitingToSend: 'waiting to send'
      }
    },

    inventory: {
      title: 'Inventory',
      dynamicTitle: 'Inventory {name}',

      table: {
        header: {
          rowNumber: 'Row',
          storeroom: 'Store',
          openingInventory: 'First Course',
          received: 'Reached warehouse',
          sent: 'Posted',
          minInventory: 'Minimum Inventory',
          onWay: 'On the way',
          totalInventory: 'Total inventory',
          waitingToSend: 'Waiting to send',
          saleInventory: 'Inventory for sale'
        }
      }
    },

    points: {
      title: 'Product Points',
      dynamicTitle: 'Point',
      documentTitle: 'Product point Document',

      table: {

        header: {
          row: 'Row',
          date: 'Date',
          documentation: 'Documents',
          score: 'Point',
          total: 'Total'
        }
      },

      types: {
        sale: 'Sale',
        purchase: 'Purchase',
        receive: 'Receive'
      }
    },

    variants: {
      title: 'Commodity variables',
      dynamicTitle: 'variables {name}',

      table: {
        header: {
          barcode: 'Barcode',
          price: 'Price',
          salePrice: 'Sale price'
        }
      }
    },

    logs: {
      title: 'Product Logs',
      dynamicTitle: '{name} Logs',

      table: {
        header: {
          rowNumber: 'Row',
          user: 'User',
          description: 'Description',
          date: 'Date'
        }
      },

      dialogs: {
        title: 'Descriptions',

        noChange: 'No field change'
      }
    },


    type: {
      simple: 'simple',
      variant: 'variable',
      service: 'service'
    }
  },

  select: {
    title: 'Product list',

    table: {
      header: {
        select: 'Select',
        rowNumber: 'Row',
        name: 'Title',
        code: 'Code',
        barcode: 'Barcode',
        price: 'Price',
        image: 'Photo',
        category: 'Category',
        type: 'Type',
        variant: 'Variable',
        salePrice: 'Sale',
        purchasePrice: 'Purchase',
        purchasePriceWithVat: 'Purchase (vat Inc)',
        quantity: 'Quantity',
        inventory: 'Inventory',
        storeroomInventory: 'Storeroom inventory',
        totalInventory: 'Total inventory',
        storeroom: 'Warehouse',
        edit: 'Edit'
      },

      filters: {
        type: {
          all: 'All',
          simple: 'simple',
          variant: 'Variable'
        }
      }
    }
  },

  trash: {
    title: 'Removed Products',

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Title',
        code: 'Code',
        image: 'Photo',
        type: 'Type',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of goods',
        body: 'Are you sure you want to delete {name} permanently?'
      }
    },

    notifications: {
      restore: {
        success: 'Product returned successfully',
        error: 'Delivery returned failed'
      },
      delete: {
        success: 'Product successfully removed',
        error: 'Deleting the product was accompanied by an error'
      }
    }
  },

  openingBalance: {
    productList: 'Product list',

    insert: {
      title: 'Add the first stock course'
    },


    productType: {
      simple: 'simple',
      variant: 'variable',
      service: 'service'
    },

    labels: {
      count: 'count',
      price: 'Price',
      delete: 'Delete first course',
      insertProduct: 'Add New Product',
      importData: 'Upload product opening balance file'
    },

    table: {
      header: {
        rowNumber: 'Row',
        barcode: 'Barcode',
        name: 'Product',
        variant: 'Variable',
        count: 'Count',
        unitPrice: 'Unit amount',
        totalPrice: 'Total amount',
        delete: 'Delete'
      }
    },

    validators: {
      valueExist: 'This value is in the list',
      productExist: '{name} is in list',
      barcode: 'Product with this barcode is not available or exists in the opening balance',
      lock: 'The opening balance is lock'
    },

    notifications: {
      insert: {
        success: 'The warehouse opening balance was successfully registered',
        error: 'The registration of the warehouse opening balance was accompanied by an error!'
      },
      importData: {
        success: 'Product opening balance file successfully uploaded',
        error: 'Upload product opening balance has error'
      },
      edit: {
        success: 'The opening balance of the product in question was successfully updated',
        error: 'The update of the product opening balance was accompanied by an error'
      },
      delete: {
        success: 'The first product was successfully deleted',
        error: 'The deletion of the desired product opening balance was accompanied by an error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete Confirmation',
        body: 'Are you sure you want to delete the first item?'
      }
    }
  },

  labels: {
    type: 'Product type',
    id: 'ID',
    barcode: 'Barcode',
    productName: 'Short Name',
    serviceName: 'service name',
    productShopName: 'Ecommerce Name',
    category: 'Category',
    serviceCategory: 'Service Category',
    price: 'Price',
    status: 'Status',
    purchasePrice: 'Purchase price',
    productPrice: 'Regular price',
    servicePrice: 'service price',
    salePrice: 'sale price',
    setSalePriceRange: 'Set schedule',
    saleStartDate: 'Sale start date',
    saleEndDate: 'Sale end date',
    phaseOut: 'Stop production',
    onlyUseral: 'Create product in useral only',
    description: 'Description',
    inventory: 'Inventory',
    chooseAttribute: 'select attribute',
    chooseCategory: 'select category',
    withoutCategory: 'without category',
    removeProduct: 'remove product',
    createVariable: 'create a new variable',
    removeVariable: 'remove variable'
  },

  validators: {
    dateHelp: 'The entry must be entered as {date}.',
    wrongInputValues: 'The information entered is incorrect',
    saleDateRange: 'Sale start and end range is incorrect',
    imageSize: 'The selected file size is too large',
    fileFormat: 'The selected file format is not allowed',
    variantExist: 'There is a variable with this feature',
    variantListTotalCount: 'The total number of possible modes is {max}',
    price: 'The price field is required',
    salePrice: 'The sale price could not be higher than the original price',
    variableSalePrice: 'Variable sale price with barcode {barcode} could not be higher than the original price',
    code: 'There is a product with this code or the code is wrong',
    barcode: 'There is a product with this barcode or the barcode is wrong',
    variableBarcode: 'Row variable barcode {row} is duplicate',
    title: 'There is a product with this name or the name is not entered correctly',
    saleStartDate: 'The sale start date is not entered correctly.',
    saleEndDate: 'The sale end date is not entered correctly.',
    productIsUsed: 'This product has turnover and cant deleted',
    variantIsUsed: 'This product variant has turnover and cant deleted',
    typeRestrict: 'This product has turnover and can\'t change the type',
    attributeEmptyValue: 'Attribute {name} has no value'
  },

  dataCategory: {
    generalInfo: 'General',
    attributes: 'Attributes',
    variables: 'variables',
    plugin: 'Integration'
  },

  productType: {
    simple: 'simple',
    variant: 'variable',
    service: 'service'
  },

  pricing: {
    title: 'Price list',

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Title',
        code: 'Code',
        barcode: 'Barcode',
        score: 'Point',
        price: 'Regular price',
        inventoryCount: 'Inventory',
        salePrice: 'Sale price',
        purchasePrice: 'Purchase price',
        purchaseAvgPrice: 'Purchase Avg. price',
        image: 'Photo',
        category: 'Category',
        variant: 'Variable',
        creator: 'Creator',
        type: 'Type',
        status: 'Status',
        shops: 'Ecommerce',
        lastModifiedDate: 'Update'
      },

      filters: {
        type: {
          all: 'All',
          simpleProduct: 'simple goods',
          variableProduct: 'Variable Goods'
        }
      }
    },

    notifications: {
      edit: {
        success: 'Prices of the product were successfully updated',
        error: 'Updated the prices of the product with the error'
      }
    },

    validators: {
      price: 'The price is not valid',
      salePrice: 'The sale price is not valid'
    },

    status: {
      notConnect: 'no connection',
      draft: 'draft',
      waiting: 'pending',
      published: 'published',
      personal: 'private'
    }
  },

  brands: {
    title: 'Brands'
  }
}
